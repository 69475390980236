import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import './index.css'

const app = createApp(App);

  Sentry.init({
    app,
    dsn: "https://ae2445f07e3ecf7ae2b6781099f4a6d8@o4508257355890688.ingest.us.sentry.io/4508311932239872",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        })
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(router);
app.mount("#app");