<template>
  <main class="flex-grow container mx-auto p-4 sm:p-6">
    <div class="text-center">
      <!-- Loading Spinner -->
      <div v-if="isLoading" class="flex justify-center items-center h-64">
        <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500 dark:border-indigo-400"></div>
      </div>

      <!-- Dashboard Content -->
      <div v-else>
        <!-- Updated Welcome Message -->
        <h2 class="text-2xl sm:text-4xl font-bold mb-8 text-gray-900 dark:text-white">Welcome, {{ fname }}</h2>

        <!-- Tabs for Assignments -->
        <div class="mb-8">
          <nav class="flex justify-center space-x-4">
            <button
                @click="currentTab = 'regular'"
                :class="currentTab === 'regular' ? activeTabClass : inactiveTabClass"
            >
              Regular Assignments
            </button>
            <button
                @click="currentTab = 'major'"
                :class="currentTab === 'major' ? activeTabClass : inactiveTabClass"
            >
              Major Assignments
            </button>
          </nav>
        </div>

        <!-- Assignments and Schedule Sections -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <!-- Regular Assignments -->
          <div
              v-if="currentTab === 'regular'"
              class="bg-white dark:bg-gray-800 bg-opacity-90 shadow-2xl rounded-2xl p-4 sm:p-8 transition-colors duration-300"
          >
            <h3 class="text-2xl sm:text-3xl font-semibold mb-6 text-gray-900 dark:text-white">Upcoming Assignments</h3>
            <ul>
              <li
                  v-for="assignment in upcomingRegularAssignments"
                  :key="assignment.assignment_id"
                  class="border-b border-gray-300 dark:border-gray-700 py-4 last:border-b-0 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                  @click="openAssignmentModal(assignment)"
              >
                <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center text-gray-800 dark:text-gray-100">
                  <div>
                    <span class="font-semibold">{{ assignment.section_name }}: </span>
                    <span>{{ assignment.short_description }}</span>
                  </div>
                  <span class="text-gray-500 dark:text-gray-400 mt-2 sm:mt-0">
                    {{ formatDate(assignment.date_due) }}
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <!-- Major Assignments -->
          <div
              v-else-if="currentTab === 'major'"
              class="bg-white dark:bg-gray-800 bg-opacity-90 shadow-2xl rounded-2xl p-4 sm:p-8 transition-colors duration-300"
          >
            <h3 class="text-2xl sm:text-3xl font-semibold mb-6 text-gray-900 dark:text-white">Upcoming Major Assignments</h3>
            <ul>
              <li
                  v-for="assignment in upcomingMajorAssignments"
                  :key="assignment.assignment_id"
                  class="border-b border-gray-300 dark:border-gray-700 py-4 last:border-b-0 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                  @click="openAssignmentModal(assignment)"
              >
                <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center text-gray-800 dark:text-gray-100">
                  <div>
                    <span class="font-semibold">{{ assignment.section_name }}: </span>
                    <span>{{ assignment.short_description }}</span>
                  </div>
                  <span class="text-gray-500 dark:text-gray-400 mt-2 sm:mt-0">
                    {{ formatDate(assignment.date_due) }}
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <!-- Today's Schedule -->
          <div class="bg-white dark:bg-gray-800 bg-opacity-90 shadow-2xl rounded-2xl p-4 sm:p-8 transition-colors duration-300">
            <h3 class="text-2xl sm:text-3xl font-semibold mb-6 text-gray-900 dark:text-white">Today's Schedule</h3>
            <div v-if="todaySchedules.length" class="overflow-x-auto">
              <table class="min-w-full table-auto text-gray-800 dark:text-gray-100">
                <thead>
                <tr class="bg-gray-200 dark:bg-gray-700">
                  <th class="px-2 sm:px-4 py-2 text-left text-gray-800 dark:text-gray-300">Time</th>
                  <th class="px-2 sm:px-4 py-2 text-left text-gray-800 dark:text-gray-300">Class</th>
                  <th class="px-2 sm:px-4 py-2 text-left text-gray-800 dark:text-gray-300">Room</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="schedule in todaySchedules"
                    :key="schedule.section_id"
                    class="border-b border-gray-300 dark:border-gray-700 even:bg-gray-50 dark:even:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <td class="px-2 sm:px-4 py-4">
                    {{ formatTime(schedule.start_time) }} - {{ formatTime(schedule.end_time) }}
                  </td>
                  <td class="px-2 sm:px-4 py-4">{{ schedule.course_title }}</td>
                  <td class="px-2 sm:px-4 py-4">{{ schedule.room_name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <p class="text-gray-600 dark:text-gray-400">No schedule available for today.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for Assignment Description -->
      <div
          v-if="showAssignmentModal"
          class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
      >
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-lg w-full transition-colors duration-300">
          <h3 class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
            {{ currentAssignment.section_name }}: {{ currentAssignment.short_description }}
          </h3>
          <!-- Preserve line breaks using 'whitespace-pre-wrap' -->
          <p class="text-gray-700 dark:text-gray-300 mb-6 whitespace-pre-wrap">{{ currentAssignment.long_description }}</p>
          <button
              @click="closeModal"
              class="bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-2 rounded-full font-semibold shadow-md transition duration-300"
          >
            Close
          </button>
        </div>
      </div>

      <!-- Modal for Adding Custom Task -->
      <div
          v-if="showAddTaskModal"
          class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
      >
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 sm:p-8 w-full max-w-md transition-colors duration-300">
          <h3 class="text-xl sm:text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Add Custom Task</h3>
          <form @submit.prevent="addTask">
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2">Section Name</label>
              <input
                  type="text"
                  v-model="newTask.section_name"
                  class="form-input w-full"
                  placeholder="e.g., Math 101"
                  required
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2">Short Description</label>
              <input
                  type="text"
                  v-model="newTask.short_description"
                  class="form-input w-full"
                  placeholder="e.g., Homework Assignment"
                  required
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2">Due Date</label>
              <input
                  type="date"
                  v-model="newTask.date_due"
                  class="form-input w-full"
                  required
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2">Long Description</label>
              <textarea
                  v-model="newTask.long_description"
                  class="form-input w-full"
                  placeholder="Detailed description..."
                  rows="4"
                  required
              ></textarea>
            </div>
            <div class="flex justify-end">
              <button
                  type="button"
                  @click="closeAddTaskModal"
                  class="bg-gray-600 dark:bg-gray-700 hover:bg-gray-700 dark:hover:bg-gray-800 text-white px-4 py-2 rounded mr-2 transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                  type="submit"
                  class="bg-green-600 dark:bg-green-700 hover:bg-green-700 dark:hover:bg-green-800 text-white px-4 py-2 rounded transition-colors duration-300"
              >
                Add Task
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> <!-- Closing text-center div -->
  </main>
</template>

<script>
import axios from 'axios';
import he from 'he';
import sanitizeHtml from 'sanitize-html';

export default {
  data() {
    return {
      token: null,
      fname: '',
      showAssignmentModal: false,
      currentAssignment: null,
      assignments: [],
      schedules: [],
      currentTab: 'regular',
      isLoading: false,
      showAddTaskModal: false,
      newTask: {
        section_name: '',
        short_description: '',
        date_due: '',
        long_description: '',
      },
      statuses: [
        {
          value: 'not-started',
          label: 'Not Started',
          activeColorClass: 'text-red-500',
          inactiveClass: 'bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300',
        },
        {
          value: 'in-progress',
          label: 'In Progress',
          activeColorClass: 'text-yellow-500',
          inactiveClass: 'bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300',
        },
        {
          value: 'complete',
          label: 'Complete',
          activeColorClass: 'text-green-500',
          inactiveClass: 'bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300',
        },
      ],
    };
  },
  computed: {
    // Filter for upcoming regular assignments
    upcomingRegularAssignments() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.assignments
          .filter((assignment) => {
            const dueDate = new Date(assignment.date_due);
            return dueDate >= today && assignment.major !== 1;
          })
          .sort((a, b) => new Date(a.date_due) - new Date(b.date_due))
          .slice(0, 5);
    },
    // Filter for upcoming major assignments
    upcomingMajorAssignments() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.assignments
          .filter((assignment) => {
            const dueDate = new Date(assignment.date_due);
            return dueDate >= today && assignment.major === 1;
          })
          .sort((a, b) => new Date(a.date_due) - new Date(b.date_due))
          .slice(0, 5);
    },
    activeTabClass() {
      return 'bg-indigo-600 text-white px-4 py-2 rounded';
    },
    inactiveTabClass() {
      return 'bg-gray-300 text-gray-700 dark:bg-gray-700 dark:text-gray-300 px-4 py-2 rounded';
    },
    todaySchedules() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.schedules
          .filter((schedule) => {
            const scheduleDate = schedule.start_time.split('T')[0];
            const todayDate = this.formatApiDate(today);
            return scheduleDate === todayDate;
          })
          .sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
    },
  },

  methods: {
    cleanAssignmentDescription(html) {
      if (!html) return '';

      // Step 1: Replace <br>, <br/>, <br />, <p>, and </p> with newline characters
      let withLineBreaks = html.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?p>/gi, '\n');

      // Step 2: Decode HTML entities
      withLineBreaks = he.decode(withLineBreaks);

      // Step 3: Strip any remaining HTML tags
      const cleanText = sanitizeHtml(withLineBreaks, {allowedTags: [], allowedAttributes: {}});

      return cleanText;
    },

    openAssignmentModal(assignment) {
      const cleanedDescription = this.cleanAssignmentDescription(assignment.long_description);
      this.currentAssignment = {...assignment, long_description: cleanedDescription};
      this.showAssignmentModal = true;
    },

    closeModal() {
      this.showAssignmentModal = false;
      this.currentAssignment = null;
    },

    openAddTaskModal() {
      this.showAddTaskModal = true;
    },

    closeAddTaskModal() {
      this.showAddTaskModal = false;
      this.newTask = {
        section_name: '',
        short_description: '',
        date_due: '',
        long_description: '',
      };
    },

    async addTask() {
      const dateParts = this.newTask.date_due.split('-');
      const assignmentDate = new Date(
          parseInt(dateParts[0], 10),
          parseInt(dateParts[1], 10) - 1,
          parseInt(dateParts[2], 10)
      );

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      assignmentDate.setHours(0, 0, 0, 0);

      if (assignmentDate < today) {
        alert('You cannot select a past date.');
        return;
      }

      const dayOfWeek = assignmentDate.getDay();

      if (dayOfWeek === 0 || dayOfWeek === 6) {
        alert('Assignments cannot be submitted on weekends.');
        return;
      }

      try {
        const response = await axios.post(
            'https://bcbackend.replit.app/custom_assignments',
            this.newTask,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
        );

        const taskToAdd = response.data;
        taskToAdd.user_task = true;
        this.assignments.push(taskToAdd);

        this.closeAddTaskModal();
      } catch (error) {
        console.error('Error adding custom task:', error);
        alert('An error occurred while adding the task. Please try again.');
      }
    },

    async deleteAssignment(assignment) {
      if (!confirm('Are you sure you want to delete this assignment?')) {
        return;
      }

      try {
        await axios.delete(
            `https://bcbackend.replit.app/custom_assignments/${assignment.assignment_id}`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
        );

        this.assignments = this.assignments.filter(
            (a) => a.assignment_id !== assignment.assignment_id
        );
      } catch (error) {
        console.error('Error deleting assignment:', error);
        alert('An error occurred while deleting the assignment. Please try again.');
      }
    },

    async updateStatus(assignment, status) {
      assignment.status = status;

      try {
        await axios.post(
            'https://bcbackend.replit.app/assignment_statuses',
            {
              assignment_id: assignment.assignment_id,
              status: status,
            },
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
        );

        await this.fetchAssignments(false);
      } catch (error) {
        console.error('Error updating assignment status:', error);
      }
    },

    truncateText(text, length) {
      if (text.length <= length) return text;
      return text.substring(0, length) + '...';
    },

    pluralize(count, singularVerbPhrase, pluralVerbPhrase) {
      return count === 1 ? singularVerbPhrase : pluralVerbPhrase;
    },

    extractToken() {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        this.token = storedToken;
      } else {
        this.token = null;
      }
    },

    isTokenExpired(token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp;
        const now = Math.floor(new Date().getTime() / 1000);
        return now > expiry;
      } catch (error) {
        console.error('Invalid token format:', error);
        return true;
      }
    },

    async fetchUsername() {
      if (!this.token || this.isTokenExpired(this.token)) {
        return;
      }
      try {
        const response = await axios.get('https://bcbackend.replit.app/username', {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.fname = response.data.fname || response.data.username;
      } catch (error) {
        console.error('Error fetching username:', error);
        if (error.response && error.response.status === 401) {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },

    async fetchAssignments(reload = true) {
      if (!this.token || this.isTokenExpired(this.token)) {
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        return;
      }
      try {
        const start_date = this.formatApiDate(new Date());
        const end_date = this.formatApiDate(
            new Date(Date.now() + 31 * 24 * 60 * 60 * 1000)
        );

        const response = await axios.get('https://bcbackend.replit.app/assignments', {
          params: {
            start_date,
            end_date,
          },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        this.assignments = response.data.value;
      } catch (error) {
        console.error('Error fetching assignments:', error);
        if (error.response && error.response.status === 401) {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },

    async fetchSchedules() {
      if (!this.token || this.isTokenExpired(this.token)) {
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        return;
      }
      try {
        const start_date = this.formatApiDate(new Date());
        const end_date = this.formatApiDate(new Date());

        const response = await axios.get('https://bcbackend.replit.app/schedule', {
          params: {
            start_date,
            end_date,
          },
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        this.schedules = response.data.value;
      } catch (error) {
        console.error('Error fetching schedule:', error);
        if (error.response && error.response.status === 401) {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },

    formatTime(timeString) {
      const [datePart, timePart] = timeString.split('T');
      const [hours, minutes] = timePart.split(':');

      const date = new Date();
      const [year, month, day] = datePart.split('-');
      date.setFullYear(parseInt(year));
      date.setMonth(parseInt(month) - 1);
      date.setDate(parseInt(day));
      date.setHours(parseInt(hours));
      date.setMinutes(parseInt(minutes));
      date.setSeconds(0);
      date.setMilliseconds(0);

      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    },

    formatApiDate(date) {
      return date.toISOString().split('T')[0];
    },
  },
  mounted() {
    this.isLoading = true;

    this.extractToken();

    if (this.token && !this.isTokenExpired(this.token)) {
      Promise.all([this.fetchUsername(), this.fetchAssignments(), this.fetchSchedules()])
          .then(() => {
            // All data fetched successfully
          })
          .catch((error) => {
            console.error('Error fetching dashboard data:', error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    } else {
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
    }
  },
};
</script>

<style scoped>
/* Spinner Customization */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  /* Adjust font sizes for mobile */
  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Table Styling */
table th,
table td {
  text-align: left;
  padding-left: 0.5rem; /* Reduced padding for mobile */
}

table th {
  font-weight: bold;
  padding: 8px 0.5rem; /* Adjusted padding */
}

table td {
  padding: 12px 0.5rem; /* Adjusted padding */
}

table {
  width: 100%;
  border-spacing: 0;
}

tr:nth-child(even) {
  background-color: #f9fafb; /* Light mode */
  dark: bg-gray-800; /* Dark mode */
}


/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0; /* Light mode scrollbar */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0; /* Light mode scrollbar hover */
}

.dark::-webkit-scrollbar-thumb {
  background: #4a5568; /* Dark mode scrollbar */
}

.dark::-webkit-scrollbar-thumb:hover {
  background: #2d3748; /* Dark mode scrollbar hover */
}
</style>
