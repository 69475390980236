<template>
  <nav class="bg-gray-200 dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-900 dark:to-gray-800 shadow-lg">
    <div class="container mx-auto p-4 flex justify-between items-center">
      <!-- Logo -->
      <div class="text-gray-800 dark:text-white font-semibold text-xl">
        BetterConnect
      </div>

      <!-- Hamburger Menu for Mobile -->
      <button @click="toggleSidebar" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white focus:outline-none md:hidden">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h7m-7 6h16" />
        </svg>
      </button>

      <!-- Navigation Links (Desktop) -->
      <ul class="hidden md:flex space-x-6 items-center">
        <li>
          <router-link to="/dashboard" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
            Home
          </router-link>
        </li>
        <li>
          <router-link to="/assignments" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
            Assignments
          </router-link>
        </li>
        <li>
          <router-link to="/schedule" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
            Schedule
          </router-link>
        </li>
        <li>
          <router-link to="/resources" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
            Resources
          </router-link>
        </li>

        <!-- Classes Dropdown -->
        <li
            v-if="isAuthenticated && classes.length"
            class="relative"
            @mouseenter="showClassesDropdown = true"
            @mouseleave="showClassesDropdown = false"
        >
          <div class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white cursor-pointer flex items-center">
            <span>Classes</span>
            <!-- Dropdown Icon -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 ml-1 text-gray-700 dark:text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <!-- Dropdown Menu -->
          <transition name="fade">
            <ul
                v-show="showClassesDropdown"
                class="absolute left-0 mt-2 w-48 bg-white dark:bg-gray-800 text-gray-700 dark:text-white rounded-md shadow-lg"
            >
              <li v-for="classItem in classes" :key="classItem.section_id">
                <router-link :to="`/class/${classItem.section_id}`" class="block px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700">
                  {{ classItem.section_display_name }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
      </ul>

      <!-- User Section (Desktop) -->
      <div class="hidden md:flex items-center space-x-4">
        <!-- Theme Toggle Button -->
        <button @click="toggleTheme" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white focus:outline-none">
          <span v-if="theme === 'light'">
            <!-- Moon Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"
              />
            </svg>
          </span>
          <span v-else>
            <!-- Sun Icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 3v1m0 16v1m8-8h1M3 12H2m15.364-7.364l.707.707M5.636 18.364l-.707.707m12.728 0l-.707-.707M5.636 5.636l-.707-.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          </span>
        </button>

        <!-- If Authenticated, Show Profile Photo and Name -->
        <div v-if="isAuthenticated" class="relative" ref="userMenu">
          <div @click="toggleUserMenu" class="flex items-center space-x-2 cursor-pointer">
            <img
                :src="profilePhotoUrl || '/default-profile.png'"
                alt="User Profile"
                class="w-12 h-12 rounded-full object-cover"
            />
            <span class="text-gray-700 dark:text-white">
              {{ firstName }} {{ lastName }}
            </span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-gray-700 dark:text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>

          <!-- Dropdown Menu -->
          <div
              v-if="showUserMenu"
              class="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 text-gray-700 dark:text-white rounded-md shadow-lg z-20"
          >
            <ul>
              <li>
                <button @click="openSettings" class="w-full text-left px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700">
                  Settings
                </button>
              </li>
              <li>
                <button @click="logout" class="w-full text-left px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>

        <!-- If Not Authenticated, Show Login Button -->
        <div v-else>
          <button
              @click="redirectToLogin"
              class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            Login
          </button>
        </div>
      </div>
    </div>

    <!-- Sidebar (Mobile) -->
    <transition name="slide">
      <div v-if="isSidebarOpen" class="fixed inset-0 flex z-50">
        <!-- Overlay -->
        <div class="fixed inset-0 bg-black opacity-50" @click="closeSidebar"></div>
        <!-- Sidebar Content -->
        <div class="relative w-64 bg-white dark:bg-gray-800 z-50">
          <div class="p-4">
            <!-- Close Button -->
            <button @click="closeSidebar" class="absolute top-4 right-4 text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <!-- Navigation Links -->
            <ul class="space-y-6 mt-8">
              <li>
                <router-link @click.native="closeSidebar" to="/dashboard" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
                  Home
                </router-link>
              </li>
              <li>
                <router-link @click.native="closeSidebar" to="/assignments" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
                  Assignments
                </router-link>
              </li>
              <li>
                <router-link @click.native="closeSidebar" to="/schedule" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
                  Schedule
                </router-link>
              </li>
              <li>
                <router-link @click.native="closeSidebar" to="/resources" class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white">
                  Resources
                </router-link>
              </li>

              <!-- Classes Dropdown -->
              <li v-if="isAuthenticated && classes.length" class="relative">
                <div
                    @click="toggleMobileClassesDropdown"
                    class="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white cursor-pointer flex items-center"
                >
                  <span>Classes</span>
                  <!-- Dropdown Icon -->
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4 ml-1 text-gray-700 dark:text-gray-300"
                      :class="{ 'transform rotate-180': showMobileClassesDropdown }"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
                <!-- Dropdown Menu -->
                <transition name="fade">
                  <ul v-show="showMobileClassesDropdown" class="mt-2 pl-4">
                    <li v-for="classItem in classes" :key="classItem.section_id">
                      <router-link
                          @click.native="closeSidebar"
                          :to="`/class/${classItem.section_id}`"
                          class="block py-2 text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white"
                      >
                        {{ classItem.section_display_name }}
                      </router-link>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>

            <!-- User Section -->
            <div class="mt-8">
              <!-- If Authenticated, Show Profile Photo and Name -->
              <div v-if="isAuthenticated" class="relative">
                <div @click="toggleMobileUserMenu" class="flex items-center space-x-2 cursor-pointer">
                  <img
                      :src="profilePhotoUrl || '/default-profile.png'"
                      alt="User Profile"
                      class="w-12 h-12 rounded-full object-cover"
                  />
                  <span class="text-gray-700 dark:text-white">
                    {{ firstName }} {{ lastName }}
                  </span>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-gray-700 dark:text-white"
                      :class="{ 'transform rotate-180': showMobileUserMenu }"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>

                <!-- Dropdown Menu -->
                <transition name="fade">
                  <div
                      v-show="showMobileUserMenu"
                      class="mt-2 bg-white dark:bg-gray-800 text-gray-700 dark:text-white rounded-md shadow-lg"
                  >
                    <ul>
                      <li>
                        <button
                            @click="handleSettings()"
                            class="w-full text-left px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700"
                        >
                          Settings
                        </button>
                      </li>
                      <li>
                        <button @click="handleLogout()" class="w-full text-left px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700">
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>

              <!-- If Not Authenticated, Show Login Button -->
              <div v-else>
                <button
                    @click="redirectToLogin"
                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-4"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Settings Modal -->
    <transition name="fade">
      <div v-if="showSettingsModal" class="fixed inset-0 flex items-center justify-center z-50">
        <div class="fixed inset-0 bg-black opacity-50"></div>
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md w-full z-10">
          <h2 class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">Settings</h2>
          <div class="mb-4">
            <label class="flex items-center text-gray-700 dark:text-gray-300">
              <input type="checkbox" v-model="digestEnabled" class="form-checkbox mr-2" />
              Enable Digest
            </label>
          </div>
          <div v-if="digestEnabled" class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2">Digest Timings:</label>
            <div class="flex items-center space-x-4">
              <label class="flex items-center text-gray-700 dark:text-gray-300">
                <input type="checkbox" value="everyMorning" v-model="digestTimings" class="form-checkbox mr-2" />
                Every Morning
              </label>
              <label class="flex items-center text-gray-700 dark:text-gray-300">
                <input type="checkbox" value="everyNight" v-model="digestTimings" class="form-checkbox mr-2" />
                Every Night
              </label>
            </div>
            <p v-if="digestTimingError" class="text-red-500 mt-1">{{ digestTimingError }}</p>
          </div>
          <div v-if="digestEnabled" class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2">Delivery Method:</label>
            <div class="flex items-center space-x-4">
              <label class="flex items-center text-gray-700 dark:text-gray-300">
                <input type="checkbox" v-model="deliveryMethod.text" class="form-checkbox mr-2" />
                Text
              </label>
            </div>
            <p v-if="deliveryMethodError" class="text-red-500 mt-1">{{ deliveryMethodError }}</p>
          </div>
          <div v-if="digestEnabled && deliveryMethod.text" class="mb-4">
            <label class="block text-gray-700 dark:text-gray-300 mb-2">Phone Number:</label>
            <input type="text" v-model="phoneNumber" class="form-input w-full" placeholder="+1xxxxxxxxxx" />
            <p v-if="phoneNumberError" class="text-red-500 mt-1">{{ phoneNumberError }}</p>
          </div>
          <div class="flex justify-end">
            <button
                @click="closeSettings"
                class="bg-gray-600 dark:bg-gray-700 text-white px-4 py-2 rounded mr-2 hover:bg-gray-700 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button @click="saveSettings" class="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-600 dark:hover:bg-blue-700">
              Save
            </button>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import { inject } from 'vue';

export default {
  data() {
    return {
      isAuthenticated: !!localStorage.getItem('token'),
      isSidebarOpen: false, // For mobile sidebar
      showMobileUserMenu: false, // Mobile user menu state
      showMobileClassesDropdown: false, // Mobile classes dropdown state
      showUserMenu: false,
      phoneNumber: '',
      profilePhotoUrl: '',
      firstName: '',
      lastName: '',
      showSettingsModal: false,
      digestEnabled: false,
      digestTimings: [],
      deliveryMethod: {
        text: true,
      },
      phoneNumberError: '',
      digestTimingError: '',
      deliveryMethodError: '',
      authCheckInterval: null,
      classes: [],
      showClassesDropdown: false,
    };
  },
  setup() {
    // Inject theme and setTheme from global provider
    const theme = inject('theme');
    const setTheme = inject('setTheme');

    const toggleTheme = () => {
      const newTheme = theme.value === 'light' ? 'dark' : 'light';
      setTheme(newTheme);
    };

    return {
      theme,
      toggleTheme,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = true;
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      this.showMobileUserMenu = false;
      this.showMobileClassesDropdown = false;
    },
    toggleMobileUserMenu() {
      this.showMobileUserMenu = !this.showMobileUserMenu;
    },
    toggleMobileClassesDropdown() {
      this.showMobileClassesDropdown = !this.showMobileClassesDropdown;
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    redirectToLogin() {
      const redirectUri = encodeURIComponent(window.location.origin + '/dashboard');
      window.location.href = `https://bcbackend.replit.app/login?originalUrl=${redirectUri}`;
    },
    logout() {
      localStorage.removeItem('token');
      this.isAuthenticated = false;
      this.profilePhotoUrl = '';
      this.firstName = '';
      this.lastName = '';
      this.classes = [];
      this.$router.push('/');
    },
    async fetchProfilePhoto() {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch('https://bcbackend.replit.app/profile_photo', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile photo');
        }

        const data = await response.json();
        this.profilePhotoUrl = data.profile_photo;
      } catch (error) {
        console.error('Error fetching profile photo:', error);
        this.profilePhotoUrl = '/default-profile.png';
      }
    },
    async fetchUserInfo() {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch('https://bcbackend.replit.app/user_info', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        this.firstName = data.firstName;
        this.lastName = data.lastName;
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    openSettings() {
      this.showUserMenu = false;
      this.showSettingsModal = true;
      this.loadSettings();
    },
    closeSettings() {
      this.showSettingsModal = false;
      this.resetErrors();
    },
    formatPhoneNumber() {
      let number = this.phoneNumber;
      number = number.replace(/\D/g, '');

      if (!number.startsWith('1')) {
        number = '1' + number;
      }

      number = '+' + number;

      if (number.length > 12) {
        number = number.slice(0, 12);
      }

      this.phoneNumber = number;
    },
    async saveSettings() {
      // Reset error messages
      this.phoneNumberError = '';
      this.digestTimingError = '';
      this.deliveryMethodError = '';

      let isValid = true;

      if (this.digestEnabled) {
        // Check if at least one digest timing is selected
        if (this.digestTimings.length === 0) {
          this.digestTimingError = 'Please select at least one digest timing.';
          isValid = false;
        }

        // Check if at least one delivery method is selected
        const deliveryMethodsSelected = Object.values(this.deliveryMethod).some((value) => value);
        if (!deliveryMethodsSelected) {
          this.deliveryMethodError = 'Please select at least one delivery method.';
          isValid = false;
        }

        // If delivery method text is enabled, validate phone number
        if (this.deliveryMethod.text) {
          const phoneRegex = /^\+1\d{10}$/;
          if (!this.phoneNumber) {
            this.phoneNumberError = 'Phone number is required when Text delivery method is selected.';
            isValid = false;
          } else if (!phoneRegex.test(this.phoneNumber)) {
            this.phoneNumberError = 'Phone number must be in the format +1xxxxxxxxxx';
            isValid = false;
          }
        }
      }

      if (!isValid) {
        return; // Prevent saving if validation fails
      }

      const settings = {
        digestEnabled: this.digestEnabled,
        digestTimings: this.digestTimings,
        deliveryMethod: this.deliveryMethod,
        phoneNumber: this.deliveryMethod.text ? this.phoneNumber : null,
      };

      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await fetch('https://bcbackend.replit.app/user_settings', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(settings),
        });

        if (!response.ok) {
          throw new Error('Failed to save settings.');
        }

        this.closeSettings();
      } catch (error) {
        console.error('Error saving settings:', error);
      }
    },
    async loadSettings() {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch('https://bcbackend.replit.app/user_settings', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 404) {
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to load settings.');
        }

        const settings = await response.json();
        this.digestEnabled = settings.digestEnabled;
        this.digestTimings = Array.isArray(settings.digestTimings) ? settings.digestTimings : [];
        this.deliveryMethod = settings.deliveryMethod || { text: true };
        this.phoneNumber = settings.phoneNumber || '';

        if (this.phoneNumber) {
          this.formatPhoneNumber();
        }
      } catch (error) {
        console.error('Error loading settings:', error);
      }
    },
    handleClickOutside(event) {
      const userMenu = this.$refs.userMenu;
      if (userMenu && !userMenu.contains(event.target)) {
        this.showUserMenu = false;
      }
    },
    checkAuthStatus() {
      const tokenExists = !!localStorage.getItem('token');
      if (tokenExists && !this.isAuthenticated) {
        this.isAuthenticated = true;
        this.fetchProfilePhoto();
        this.fetchUserInfo();
        this.loadSettings();
        this.fetchClasses();
      } else if (!tokenExists && this.isAuthenticated) {
        this.isAuthenticated = false;
        this.profilePhotoUrl = '';
        this.firstName = '';
        this.lastName = '';
        this.showUserMenu = false;
        this.showSettingsModal = false;
        this.classes = [];
      }
    },
    resetErrors() {
      this.phoneNumberError = '';
      this.digestTimingError = '';
      this.deliveryMethodError = '';
    },
    async fetchClasses() {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch('https://bcbackend.replit.app/classes', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch classes');
        }

        const data = await response.json();
        this.classes = data.value || [];
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    },
    handleSettings() {
      this.openSettings();
      this.closeSidebar();
    },
    handleLogout() {
      this.logout();
      this.closeSidebar();
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.fetchProfilePhoto();
      this.fetchUserInfo();
      this.loadSettings();
      this.fetchClasses();
    }
  },
  mounted() {
    this.authCheckInterval = setInterval(this.checkAuthStatus, 1000);
  },
  watch: {
    showUserMenu(newVal) {
      if (newVal) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    phoneNumber(newVal) {
      this.formatPhoneNumber();
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    if (this.authCheckInterval) {
      clearInterval(this.authCheckInterval);
    }
  },
};
</script>

<style scoped>
/* Fade transition for the settings modal and dropdown */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Slide transition for the sidebar */
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.form-input {
  background-color: #f9fafb; /* Light background for light mode */
  color: #1f2937; /* Dark text for light mode */
  border: 1px solid #d1d5db; /* Light border for light mode */
  padding: 0.5rem;
  border-radius: 0.375rem;
}

/* Dark mode styles for form-input */
.dark .form-input {
  background-color: #374151; /* Dark background */
  color: #f9fafb; /* Light text */
  border: 1px solid #4b5563; /* Dark border */
}

/* Optional: Adjust placeholder color in dark mode */
.dark .form-input::placeholder {
  color: #9ca3af;
}


.form-input::placeholder {
  color: #9ca3af;
}

.text-red-500 {
  color: #f56565;
}

/* Styling for checkboxes */
.form-checkbox {
  /* Remove default appearance */
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #cbd5e0;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  cursor: pointer;
  transition: border-color 0.2s, background-color 0.2s;
}

.form-checkbox:checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.form-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

/* Ensure the dropdown menu is above other content */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-20 {
  z-index: 20;
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  .text-xl {
    font-size: 1.25rem;
  }
}


</style>
