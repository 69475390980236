<!-- schedule.vue -->
<template>
  <main class="flex-grow container mx-auto p-4 sm:p-6">
    <div class="text-center">
      <!-- Week Navigation -->
      <div class="flex flex-col sm:flex-row justify-between items-center mb-6">
        <button
            @click="previousWeek"
            class="bg-gray-300 hover:bg-gray-400 text-black dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white font-semibold py-2 px-4 rounded-l mb-2 sm:mb-0 transition-colors duration-300"
        >
          &lt; Previous Week
        </button>
        <div>
          <h3 class="text-2xl sm:text-3xl font-semibold text-gray-900 dark:text-white">Week of {{ formatDate(currentWeekStart) }}</h3>
          <!-- Week Number Indicator -->
          <p class="text-lg text-gray-500 dark:text-gray-400">Week {{ weekNumber }}</p>
        </div>
        <button
            @click="nextWeek"
            class="bg-gray-300 hover:bg-gray-400 text-black dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white font-semibold py-2 px-4 rounded-r mt-2 sm:mt-0 transition-colors duration-300"
        >
          Next Week &gt;
        </button>
      </div>

      <!-- Day Navigation -->
      <div class="flex flex-wrap justify-center mb-6 space-x-2">
        <button
            v-for="(day, index) in daysOfWeek"
            :key="index"
            @click="currentDayIndex = index"
            :class="currentDayIndex === index ? 'bg-indigo-600 text-white' : 'bg-gray-300 text-black dark:bg-gray-700 dark:text-white'"
            class="hover:bg-indigo-500 font-semibold py-2 px-4 rounded mb-2 transition-colors duration-300"
        >
          {{ day }}
        </button>
      </div>

      <!-- Schedule Table with Breaks -->
      <div class="bg-white dark:bg-gray-800 bg-opacity-90 shadow-2xl rounded-2xl p-4 sm:p-8 transition-colors duration-300">
        <h4 class="text-xl sm:text-2xl font-semibold mb-6 text-gray-900 dark:text-white">{{ daysOfWeek[currentDayIndex] }}'s Schedule</h4>
        <div v-if="todaySchedulesWithBreaks.length" class="overflow-x-auto">
          <table class="min-w-full table-auto text-gray-800 dark:text-gray-100">
            <thead>
            <tr class="bg-gray-200 dark:bg-gray-700">
              <th class="px-2 sm:px-4 py-2 text-left font-semibold">Time</th>
              <th class="px-2 sm:px-4 py-2 text-left font-semibold">Class</th>
              <th class="px-2 sm:px-4 py-2 text-left font-semibold">Room</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="schedule in todaySchedulesWithBreaks"
                :key="schedule.section_id || schedule.start_time"
                class="border-b border-gray-300 dark:border-gray-700 even:bg-gray-50 dark:even:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <td class="px-2 sm:px-4 py-4">
                <!-- Check if it's a break or a class -->
                <div v-if="schedule.isBreak">
                  <p class="text-gray-500 dark:text-gray-400">
                    {{ schedule.duration }} minutes until next class
                  </p>
                </div>
                <div v-else>
                  {{ formatTime(schedule.start_time) }} - {{ formatTime(schedule.end_time) }}
                </div>
              </td>
              <td class="px-2 sm:px-4 py-4" v-if="!schedule.isBreak">{{ schedule.course_title }}</td>
              <td class="px-2 sm:px-4 py-4" v-if="!schedule.isBreak">{{ schedule.room_name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p class="text-gray-600 dark:text-gray-400">No schedule available for this day.</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      token: null,
      username: '',
      currentDayIndex: 0,
      currentWeekStart: new Date(),
      schedules: [],
      daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      referenceStartDate: new Date('2023-11-11'),
    };
  },
  computed: {
    // Week Number Computation
    weekNumber() {
      const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
      const diffInMs = this.currentWeekStart - this.referenceStartDate;
      const diffInWeeks = Math.floor(diffInMs / oneWeekInMs);
      // If the difference is even, it's Week 1; if odd, Week 2
      return (diffInWeeks % 2 === 0) ? 1 : 2;
    },

    // Get the current day's schedules and include breaks
    todaySchedulesWithBreaks() {
      const schedulesWithBreaks = [];
      const currentDate = this.getDateByDay(this.currentWeekStart, this.currentDayIndex);
      const formattedDate = this.formatApiDate(currentDate);

      const todaySchedules = this.schedules
          .filter(schedule => schedule.start_time.split('T')[0] === formattedDate)
          .sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

      for (let i = 0; i < todaySchedules.length; i++) {
        const currentClass = todaySchedules[i];
        schedulesWithBreaks.push(currentClass);

        if (i < todaySchedules.length - 1) {
          const nextClass = todaySchedules[i + 1];
          const currentClassEnd = new Date(currentClass.end_time);
          const nextClassStart = new Date(nextClass.start_time);

          const timeDiffMinutes = (nextClassStart - currentClassEnd) / (1000 * 60);

          if (timeDiffMinutes >= 15) { // Show breaks only if 15 minutes or more
            schedulesWithBreaks.push({
              isBreak: true,
              start_time: currentClass.end_time,
              end_time: nextClass.start_time,
              duration: timeDiffMinutes
            });
          }
        }
      }
      return schedulesWithBreaks;
    },
  },
  created() {
    this.extractToken();
    this.initializeWeekAndDay();
    this.fetchUsername();
    this.fetchSchedules();
  },
  methods: {
    initializeWeekAndDay() {
      this.currentWeekStart = this.getCurrentWeekStart();
      this.currentDayIndex = this.getCurrentDayIndex();
    },
    formatDate(date) {
      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    },
    extractToken() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (token) {
        this.token = token;
        localStorage.setItem('token', token);

        // Remove token from URL without reloading the page
        urlParams.delete('token');
        const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
        window.history.replaceState({}, document.title, newUrl);
      } else {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          this.token = storedToken;
        } else {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },
    isTokenExpired(token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp;
        const now = Math.floor(new Date().getTime() / 1000);
        return now > expiry;
      } catch (error) {
        console.error('Invalid token format:', error);
        return true; // Consider invalid token as expired
      }
    },
    async fetchUsername() {
      if (!this.token || this.isTokenExpired(this.token)) {
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        return;
      }
      try {
        const response = await axios.get('https://bcbackend.replit.app/username', {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.username = response.data.username;
      } catch (error) {
        console.error('Error fetching username:', error);
        if (error.response && error.response.status === 401) {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },
    async fetchSchedules() {
      if (!this.token || this.isTokenExpired(this.token)) {
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        return;
      }
      try {
        const start_date = this.formatApiDate(this.currentWeekStart);
        const end_date = this.formatApiDate(new Date(this.currentWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000));

        const response = await axios.get('https://bcbackend.replit.app/schedule', {
          params: { start_date, end_date },
          headers: { Authorization: `Bearer ${this.token}` },
        });

        this.schedules = response.data.value;
      } catch (error) {
        console.error('Error fetching schedule:', error);
        if (error.response && error.response.status === 401) {
          const currentUrl = encodeURIComponent(window.location.href);
          window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
        }
      }
    },
    formatApiDate(date) {
      return date.toISOString().split('T')[0];
    },
    formatTime(timeString) {
      const [datePart, timePart] = timeString.split('T');
      const [hours, minutes] = timePart.split(':');

      const date = new Date();
      const [year, month, day] = datePart.split('-');
      date.setFullYear(parseInt(year));
      date.setMonth(parseInt(month) - 1);
      date.setDate(parseInt(day));
      date.setHours(parseInt(hours));
      date.setMinutes(parseInt(minutes));
      date.setSeconds(0);
      date.setMilliseconds(0);

      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    },
    getDateByDay(weekStart, dayIndex) {
      if (!weekStart || !(weekStart instanceof Date)) {
        console.error('Invalid weekStart:', weekStart);
        return new Date();
      }

      const date = new Date(weekStart.getTime());
      date.setDate(weekStart.getDate() + dayIndex);
      date.setHours(0, 0, 0, 0);

      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    },
    getCurrentDayIndex() {
      const now = new Date();
      const dayOfWeek = now.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      let index;
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        index = dayOfWeek - 1; // Monday is 0, ..., Friday is 4

        if (currentHour > 16 || (currentHour === 16 && currentMinute >= 0)) {
          // After 4 PM, move to the next day
          index += 1;
          if (index > 4) {
            index = 0; // Wrap around to Monday
            this.currentWeekStart = this.getNextWeekStart(this.currentWeekStart);
          }
        }
      } else {
        // If today is Saturday or Sunday, default to Monday
        index = 0;
      }

      return index;
    },
    getNextWeekStart(currentStart) {
      if (!currentStart || !(currentStart instanceof Date)) {
        console.error('Invalid currentStart:', currentStart);
        return new Date(); // Return current date as fallback
      }

      const nextWeekStart = new Date(currentStart);
      nextWeekStart.setDate(currentStart.getDate() + 7);
      nextWeekStart.setHours(0, 0, 0, 0);
      return nextWeekStart;
    },
    getCurrentWeekStart() {
      const today = new Date();
      const dayOfWeek = today.getDay();
      let weekStartDate;
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        // If today is Monday to Friday, get the most recent Monday
        const daysSinceMonday = dayOfWeek - 1;
        weekStartDate = new Date(today);
        weekStartDate.setDate(today.getDate() - daysSinceMonday);
      } else {
        // If today is Saturday or Sunday, get the next Monday
        const daysUntilMonday = (8 - dayOfWeek) % 7;
        weekStartDate = new Date(today);
        weekStartDate.setDate(today.getDate() + daysUntilMonday);
      }
      weekStartDate.setHours(0, 0, 0, 0);
      return weekStartDate;
    },
    nextWeek() {
      const nextWeekDate = new Date(this.currentWeekStart);
      nextWeekDate.setDate(this.currentWeekStart.getDate() + 7);
      this.currentWeekStart = nextWeekDate;
      this.currentDayIndex = 0;
      this.fetchSchedules();
    },
    previousWeek() {
      const previousWeekDate = new Date(this.currentWeekStart);
      previousWeekDate.setDate(this.currentWeekStart.getDate() - 7);
      this.currentWeekStart = previousWeekDate;
      this.currentDayIndex = 0;
      this.fetchSchedules();
    },
  },
};
</script>

<style scoped>
/* Spinner Animation */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0; /* Light mode scrollbar */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0; /* Light mode scrollbar hover */
}

.dark ::-webkit-scrollbar-thumb {
  background: #4a5568; /* Dark mode scrollbar */
}

.dark ::-webkit-scrollbar-thumb:hover {
  background: #2d3748; /* Dark mode scrollbar hover */
}

table {
  width: 100%;
  border-spacing: 0;
}
</style>
