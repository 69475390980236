<template>
  <nav class="bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 shadow-lg">
    <div class="container mx-auto p-4 flex justify-between items-center">
      <!-- Logo -->
      <div class="text-white font-semibold text-xl">
        BetterConnect
      </div>

      <button
          @click="redirectToLogin"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
      >
        Login
      </button>
    </div>
      </nav>
  <div class="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white flex flex-col">
    <!-- Hero Section -->
    <header class="flex flex-col justify-center items-center text-center py-16 px-6 overflow-visible">
      <!-- Add 'leading-tight' to adjust line-height and 'overflow-visible' to prevent clipping -->
      <h1 class="text-5xl md:text-7xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 fix-descender">
        Welcome to the Future of Assignment Management
      </h1>
      <p class="text-lg md:text-xl text-gray-300 mb-8">
        Explore a new and improved platform, designed to help you manage your workload efficiently and effectively.
      </p>
      <ul class="text-gray-300 text-left mb-8">
        <li class="flex items-center mb-2">
          <svg class="w-6 h-6 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
          No more frequent logins - once and done.
        </li>
        <li class="flex items-center mb-2">
          <svg class="w-6 h-6 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
          Text digest with upcoming assignments and schedule.
        </li>
        <li class="flex items-center mb-2">
          <svg class="w-6 h-6 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
          Aggregate assignment statuses.
        </li>
        <li class="flex items-center mb-2">
          <svg class="w-6 h-6 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
          Flag for assignments breaking the 1-2-3 and 2-3-4 rules.
        </li>
        <li class="flex items-center mb-2">
          <svg class="w-6 h-6 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
          Cleaner, more reliable, and more efficient than Connect.
        </li>
        <li class="flex items-center mb-2">
          <svg class="w-6 h-6 text-green-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414L8.414 15l-4.707-4.707a1 1 0 011.414-1.414L8.414 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
          Dark mode.
        </li>
      </ul>
      <button
          class="bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 text-white px-6 py-3 rounded-full text-lg font-semibold shadow-lg transform hover:scale-105 transition-transform duration-300"
          @click="redirectToLogin"
      >
        Explore New Version
      </button>
    </header>

    <!-- Feature Preview Section -->
    <section class="container mx-auto py-16 px-6">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        <!-- Upcoming Assignments Preview -->
        <div class="bg-gradient-to-r from-gray-800 to-gray-900 shadow-2xl rounded-2xl p-8">
          <h3 class="text-3xl font-semibold mb-6">Upcoming Assignments</h3>
          <ul>
            <li
                v-for="assignment in assignments"
                :key="assignment.id"
                class="border-b border-gray-600 py-4 last:border-b-0 cursor-pointer hover:bg-gray-800 rounded-lg transition-colors duration-300"
                @click="openModal(assignment)"
            >
              <div class="flex justify-between items-center text-gray-100">
                <div>
                  <span class="font-semibold">{{ assignment.section }}: </span>
                  <span>{{ assignment.title }}</span>
                </div>
                <span class="text-gray-400">{{ assignment.dueDate }}</span>
              </div>
              <!-- Completion Status -->
              <div class="mt-2">
                <div class="w-full bg-gray-700 rounded-full h-2">
                  <div
                      class="bg-green-500 h-2 rounded-full"
                      :style="{ width: (assignment.completedBy / assignment.totalStudents * 100) + '%' }"
                  ></div>
                </div>
                <p class="text-sm text-gray-400 mt-1">
                  {{ assignment.completedBy }} of {{ assignment.totalStudents }} students completed
                </p>
              </div>
            </li>
          </ul>
        </div>

        <!-- Daily Schedule Preview -->
        <div class="bg-gradient-to-r from-gray-800 to-gray-900 shadow-2xl rounded-2xl p-8">
          <h3 class="text-3xl font-semibold mb-6">Today's Schedule</h3>
          <div class="overflow-x-auto">
            <table class="min-w-full table-auto text-gray-100">
              <thead>
              <tr class="bg-gray-700">
                <th class="px-4 py-2 text-left">Time</th>
                <th class="px-4 py-2 text-left">Class</th>
                <th class="px-4 py-2 text-left">Room</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="schedule in schedules" :key="schedule.id" class="border-b border-gray-600">
                <td class="px-4 py-4">{{ schedule.time }}</td>
                <td class="px-4 py-4">{{ schedule.class }}</td>
                <td class="px-4 py-4">{{ schedule.room }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>


    <!-- Modal for Assignment Description -->
    <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div class="bg-gray-800 rounded-lg shadow-lg p-8 max-w-lg w-full">
        <h3 class="text-2xl font-semibold mb-4">{{ currentAssignment.section }}: {{ currentAssignment.title }}</h3>
        <p class="text-gray-300 mb-6">{{ currentAssignment.description }}</p>
        <button @click="closeModal" class="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      currentAssignment: null,
      assignments: [
        {
          id: 1,
          section: 'Biology',
          title: 'Homework',
          dueDate: 'Aug 25, 2024',
          description: 'Complete the worksheet on photosynthesis and submit it by the due date.',
          completedBy: 15,
          totalStudents: 20,
        },
        {
          id: 2,
          section: 'French',
          title: 'Essay',
          dueDate: 'Aug 27, 2024',
          description: 'Write a 500-word essay on the influence of French culture in the modern world.',
          completedBy: 10,
          totalStudents: 20,
        },
        {
          id: 3,
          section: 'Math',
          title: 'Geometry Quiz',
          dueDate: 'Aug 30, 2024',
          description: 'Study for the upcoming geometry quiz covering chapters 4 and 5.',
          completedBy: 5,
          totalStudents: 20,
        },
      ],
      schedules: [
        { id: 1, time: '8:00 AM - 9:00 AM', class: 'Biology', room: 'US Science 708' },
        { id: 2, time: '9:15 AM - 10:15 AM', class: 'French', room: 'US Classroom 501' },
        { id: 3, time: '10:30 AM - 11:30 AM', class: 'English', room: 'US Classroom 613' },
        { id: 4, time: '12:30 PM - 1:30 PM', class: 'Geometry', room: 'US Classroom 606' },
        { id: 5, time: '1:45 PM - 2:45 PM', class: 'Programming', room: 'US Science 712' },
        { id: 6, time: '3:00 PM - 4:00 PM', class: 'Latin', room: 'US Classroom 611' },
      ],
      phoneNumber: '',
      digestTime: 'morning',
    };
  },
  methods: {
    redirectToLogin() {
      const redirectUri = encodeURIComponent(window.location.origin + '/dashboard');
      window.location.href = `https://bcbackend.replit.app/login?originalUrl=${redirectUri}`;
    },
    openModal(assignment) {
      this.currentAssignment = assignment;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.currentAssignment = null;
    },
  },
};
</script>

<style scoped>
/* Theme-specific styles for the homepage */
body {
  @apply bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white flex flex-col font-poppins;
}

button {
  @apply transition-transform duration-300;
}

footer {
  @apply text-gray-500;
}

h1 {
  @apply text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500;
}

.fix-descender {
  line-height: 1.2 !important;
  overflow: visible !important;
}
</style>
<script setup lang="ts">
</script>