<!-- resources.vue -->
<template>
  <div class="container mx-auto p-6">
    <h1 class="text-4xl font-bold mb-8 text-gray-900 dark:text-white text-center">Resources</h1>

    <!-- Accordion for Resources -->
    <div class="space-y-4">
      <div
          v-for="(section, index) in sections"
          :key="index"
          class="border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden transition-colors duration-300"
      >
        <button
            @click="toggleSection(index)"
            class="w-full text-left px-4 py-3 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 flex justify-between items-center transition-colors duration-300"
        >
          <span class="text-xl font-semibold text-gray-900 dark:text-white">{{ section.title }}</span>
          <svg
              :class="{'transform rotate-180': section.isOpen}"
              class="w-5 h-5 text-gray-500 dark:text-gray-300 transition-transform duration-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div v-show="section.isOpen" class="bg-white dark:bg-gray-800 px-6 py-4 transition-colors duration-300">
          <!-- List of Resources -->
          <ul class="list-disc pl-5 space-y-2 text-gray-700 dark:text-gray-300">
            <li v-for="(resource, idx) in section.resources" :key="idx">
              <a :href="resource.link" target="_blank" class="text-blue-500 dark:text-blue-400 hover:underline">
                {{ resource.title }}
              </a>
              <p v-if="resource.description" class="text-sm text-gray-500 dark:text-gray-400 ml-4">
                {{ resource.description }}
              </p>
            </li>
          </ul>
          <!-- Additional content for Library News -->
          <div v-if="section.news" class="mt-6">
            <h3 class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">News</h3>
            <ul class="space-y-6">
              <li v-for="(newsItem, idx) in section.news" :key="idx">
                <p class="text-gray-700 dark:text-gray-300"><strong>{{ newsItem.title }}</strong></p>
                <div v-for="(content, cIdx) in newsItem.contents" :key="cIdx">
                  <p v-if="content.text" class="text-gray-600 dark:text-gray-400">{{ content.text }}</p>
                  <ul v-if="content.links" class="list-disc pl-5 space-y-2">
                    <li v-for="(link, lIdx) in content.links" :key="lIdx">
                      <a :href="link.url" target="_blank" class="text-blue-500 dark:text-blue-400 hover:underline">
                        {{ link.title }}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Resources',
  data() {
    return {
      sections: [
        {
          title: 'Lunch Menu',
          isOpen: false,
          resources: [
            {
              title: 'View Current Lunch Menu',
              link: 'https://collegiateschool.flikisdining.com/menu/dining-hall/lunch/',
            },
          ],
        },
        {
          title: 'Library',
          isOpen: false,
          resources: [
            {
              title: 'Library Homepage',
              link: 'https://www.collegiateschool.org/academics/library',
              description: 'Hours: 7:30am - 5:00pm Monday - Friday',
            },
            {
              title: 'Sora Collection',
              link: 'https://soraapp.com/library/hvlany',
            },
            {
              title: 'Lower School Databases',
              link: 'https://libguides.collegiateschool.org/lowerschooldatabases',
              description: 'Password: collegiateschoollibrary',
            },
            {
              title: 'Middle/Upper School Databases',
              link: 'https://libguides.collegiateschool.org/middleupperschooldatabases',
              description: 'Password: collegiateschoollibrary',
            },
            {
              title: 'Catalog',
              link: 'http://follett.collegiateschool.org/',
            },
            {
              title: 'Book Request Form',
              link: 'https://forms.gle/KUp1CboZTKwz1TmT8',
            },
          ],
          // Library News
          news: [
            {
              title: 'Sora Tutorials',
              contents: [
                {
                  links: [
                    {
                      title: 'Sora Login',
                      url: 'https://youtu.be/RIXicV3Z8yE',
                    },
                    {
                      title: 'Sora Non-School Account Login',
                      url: 'https://youtu.be/al5MyUqLTro',
                    },
                    {
                      title: 'Sora Tour',
                      url: 'https://youtu.be/0JyIlMzi3bc',
                    },
                    {
                      title: 'Sora: Adding a Library Account',
                      url: 'https://youtu.be/sRcmLeWuJSI',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: 'Important Links and Resources',
          isOpen: false,
          resources: [
            {
              title: '301 Freedom Place South Floor Plan',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_2299406.pdf?_=1731119376018',
            },
            {
              title: 'Calendar Year-at-a-Glance',
              link: 'https://resources.finalsite.net/images/v1686680751/collegiateschoolorg/acbbsnpzq7i9hagkyd8g/2023-2024CollegiateCalendar_final.pdf',
            },
            {
              title: 'Allergy Policy',
              link: 'https://www.collegiateschool.org/fs/resource-manager/view/d17d613c-73a1-4b63-a47b-f7bc0c0e3dcf',
            },
            {
              title: 'Lost Card Report/Request for New Card',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_group6783_id373769.pdf?_=1731119376022',
            },
          ],
        },
        {
          title: 'Upper School Downloads',
          isOpen: false,
          resources: [
            {
              title: 'US Handbook',
              link: 'https://www.collegiateschool.org/fs/resource-manager/view/240888a3-4017-4468-a887-ec6b5d9f2fe9',
            },
            {
              title: 'Upper School Databases',
              link: 'https://libguides.collegiateschool.org/middleupperschooldatabases',
              description: 'Password: collegiateschoollibrary',
            },
            {
              title: 'Digital US Schedule',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/Master%20Schedule%209-12empty23.pdf?_=1731119376022',
            },
            {
              title: 'Acceptable Use Policy',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_1661857.pdf?_=1731119376022',
            },
            {
              title: 'The Dread of Writing Essays and Papers',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_group6783_id173024.pdf?_=1731119376022',
            },
            {
              title: 'Fall 2023 Course Guide',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_7098497.pdf?_=1731119376022',
            },
          ],
        },
        {
          title: 'Middle School Downloads',
          isOpen: false,
          resources: [
            {
              title: 'MS Handbook',
              link: 'https://www.collegiateschool.org/fs/resource-manager/view/c1effcf6-d647-4756-a115-8d00aed6496f',
            },
            {
              title: 'Middle School Databases',
              link: 'https://libguides.collegiateschool.org/middleupperschooldatabases',
              description: 'Password: collegiateschoollibrary',
            },
            {
              title: 'Acceptable Use Policy',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_1661857.pdf?_=1731119376021',
            },
          ],
        },
        {
          title: 'Athletics Downloads',
          isOpen: false,
          resources: [
            {
              title: 'Exemption Form Grades 7-12',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_1316131.pdf?_=1731119376021',
            },
            {
              title: 'Athletics Unaccompanied Dismissal Form',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_2137942.pdf?_=1731119376021',
              description: 'For off-campus athletic events that finish after school, parents may request permission for a student’s dismissal from the event location.',
            },
            {
              title: 'Preseason Soccer',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_8248770.pdf?_=1731119376021',
              description: 'Preseason training camp for students interested in trying out for JV/Varsity soccer.',
            },
            {
              title: 'Cross Country Training Camp',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_8248766.pdf?_=1731119376021',
              description: 'Camp Sloane is the preseason training camp for students interested in trying out for cross country.',
            },
            {
              title: 'Athletics Handbook',
              link: 'https://collegiateschool.myschoolapp.com/ftpimages/37/download/download_1327587.pdf?_=1731119376021',
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleSection(index) {
      this.sections[index].isOpen = !this.sections[index].isOpen;
    },
  },
};
</script>

<style scoped>
/* Removed body styles to prevent conflicts */

/* Fade transition for the accordion */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
