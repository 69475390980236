<!-- 404.vue -->
<template>
  <div class="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900 text-gray-900 dark:text-white transition-colors duration-300">
    <div class="text-center max-w-md px-4">
      <!-- Illustration -->
      <svg
          class="mx-auto mb-6 w-24 h-24 text-gray-500 dark:text-gray-300 animate-pulse"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
      >
        <path
            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M12 21c4.97 0 9-4.03 9-9S16.97 3 12 3 3 7.03 3 12s4.03 9 9 9z"
        />
      </svg>

      <!-- Error Code -->
      <h1 class="text-6xl font-extrabold">404</h1>

      <!-- Error Message -->
      <p class="mt-4 text-lg">
        Oops! The page you're looking for doesn't exist.
      </p>

      <!-- Navigation Button -->
      <button
          @click="goHome"
          class="mt-6 px-6 py-3 bg-blue-600 dark:bg-blue-700 text-white rounded-md hover:bg-blue-700 dark:hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300"
      >
        Go Back Home
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { onMounted, onUnmounted } from 'vue';

const router = useRouter();

const goHome = () => {
  router.push({ name: 'Dashboard' });
};

// Prevent body from scrolling when 404 page is active
onMounted(() => {
  document.body.classList.add('overflow-hidden');
});

onUnmounted(() => {
  document.body.classList.remove('overflow-hidden');
});
</script>

<style scoped>
/* Transition for background colors */
.fixed {
  transition: background-color 0.3s ease;
}

.text-gray-500 {
  transition: color 0.3s ease;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
