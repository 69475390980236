<!-- App.vue -->
<script setup>
import { ref, computed, watch, provide } from 'vue';
import { useRoute } from 'vue-router';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

// Theme management
const theme = ref(localStorage.getItem('theme') || 'dark');

const setTheme = (newTheme) => {
  theme.value = newTheme;
  localStorage.setItem('theme', newTheme);
};

provide('theme', theme);
provide('setTheme', setTheme);

watch(theme, (newTheme) => {
  if (newTheme === 'dark') {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
});

// Apply initial theme
if (theme.value === 'dark') {
  document.documentElement.classList.add('dark');
}

const route = useRoute();

  const showNavbar = computed(() => {
    return route.name !== 'Landing' && route.name !== 'Privacy' && route.name !== 'Terms';
  });


const showFooter = computed(() => {
  return route.name !== 'Resources';
});
</script>

<template>
  <div
      id="app"
      class="min-h-screen flex flex-col bg-slate-100 text-black dark:bg-gradient-to-r dark:from-gray-900 dark:via-black dark:to-gray-900 dark:text-white font-poppins"
>
  <Navbar v-if="showNavbar" />
    <router-view />
    <Footer v-if="showFooter" />
  </div>
</template>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

.font-poppins {
  font-family: 'Poppins', sans-serif;
}
</style>
