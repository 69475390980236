<template>
  <main class="flex-grow container mx-auto p-6">
    <!-- Loading Spinner -->
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-indigo-500 dark:border-indigo-400"></div>
    </div>

    <!-- Error Message -->
    <div v-else-if="errorMessage" class="text-center text-red-500">
      <p>{{ errorMessage }}</p>
    </div>

    <!-- Class Content -->
    <div v-else>
      <!-- Class Heading -->
      <h2 class="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-white">{{ classData.section_display_name }}</h2>

      <!-- Class Overview and Assignments Sections -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Class Overview -->
        <div class="bg-white dark:bg-gray-800 shadow-2xl rounded-2xl p-8 transition-colors duration-300">
          <h3 class="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">Class Overview</h3>
          <ul class="text-gray-700 dark:text-gray-300">
            <li class="mb-4">
              <span class="font-semibold">Cumulative Grade: </span>
              <span>{{ classData.cumulative_grade !== null ? classData.cumulative_grade + '%' : 'N/A' }}</span>
            </li>
            <li class="mb-4">
              <span class="font-semibold">Next Meeting: </span>
              <span>{{ nextMeetingTime ? formatDateTime(nextMeetingTime) : 'No upcoming meetings' }}</span>
            </li>
            <li class="mb-4">
              <span class="font-semibold">Next Assignment Due: </span>
              <span>
                <span v-if="classData.next_assignment_due">
                  {{ classData.next_assignment_due.name }} - {{ formatDate(classData.next_assignment_due.due_date) }}
                </span>
                <span v-else>No upcoming assignments</span>
              </span>
            </li>
          </ul>
        </div>

        <!-- Assignments Section -->
        <div class="bg-white dark:bg-gray-800 shadow-2xl rounded-2xl p-8 transition-colors duration-300">
          <h3 class="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">Upcoming Assignments</h3>
          <ul>
            <li
                v-for="assignment in upcomingAssignments"
                :key="assignment.assignment_id"
                class="border-b border-gray-300 dark:border-gray-700 py-4 last:border-b-0 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                @click="openAssignmentModal(assignment)"
            >
              <div class="flex justify-between items-center text-gray-800 dark:text-gray-100">
                <div>
                  <span class="font-semibold">{{ assignment.name }}</span>
                </div>
                <span class="text-gray-500 dark:text-gray-400">{{ formatDate(assignment.due_date) }}</span>
              </div>
            </li>
          </ul>
        </div>

        <!-- Graded Assignments Section -->
        <div class="bg-white dark:bg-gray-800 shadow-2xl rounded-2xl p-8 md:col-span-2 transition-colors duration-300">
          <h3 class="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">Graded Assignments</h3>
          <div v-if="gradedAssignments.length" class="overflow-x-auto">
            <table class="min-w-full table-auto text-gray-800 dark:text-gray-100">
              <thead>
              <tr class="bg-gray-200 dark:bg-gray-700">
                <th class="px-4 py-2 text-left">Assignment</th>
                <th class="px-4 py-2 text-left">Due Date</th>
                <th class="px-4 py-2 text-left">Grade</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="assignment in gradedAssignments"
                  :key="assignment.assignment_id"
                  class="border-b border-gray-300 dark:border-gray-700 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
              >
                <td class="px-4 py-4">{{ assignment.short_description }}</td>
                <td class="px-4 py-4">{{ formatDate(assignment.date_due) }}</td>
                <td class="px-4 py-4">
                  {{ assignment.assignment_percentage !== null ? assignment.assignment_percentage + '%' : 'N/A' }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="text-gray-600 dark:text-gray-400">No graded assignments available.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for Assignment Description -->
    <div
        v-if="showAssignmentModal"
        class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
    >
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-lg w-full transition-colors duration-300">
        <h3 class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
          {{ currentAssignment.name }}
        </h3>
        <!-- Preserve line breaks using 'whitespace-pre-wrap' -->
        <p class="text-gray-700 dark:text-gray-300 mb-6 whitespace-pre-wrap">{{ currentAssignment.description }}</p>
        <button
            @click="closeAssignmentModal"
            class="bg-indigo-600 dark:bg-indigo-700 hover:bg-indigo-700 dark:hover:bg-indigo-800 text-white px-4 py-2 rounded transition-colors duration-300"
        >
          Close
        </button>
      </div>
    </div>

    <!-- Modal for Graded Assignment Details -->
    <div
        v-if="showGradedAssignmentModal"
        class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4"
    >
      <div class="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-lg w-full transition-colors duration-300">
        <h3 class="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
          {{ currentGradedAssignment.short_description }}
        </h3>
        <ul class="text-gray-700 dark:text-gray-300 mb-6">
          <li class="mb-2">
            <span class="font-semibold">Due Date:</span> {{ formatDate(currentGradedAssignment.date_due) }}
          </li>
          <li class="mb-2">
            <span class="font-semibold">Grade:</span>
            {{ currentGradedAssignment.assignment_percentage !== null ? currentGradedAssignment.assignment_percentage + '%' : 'N/A' }}
          </li>
        </ul>
        <button
            @click="closeGradedAssignmentModal"
            class="bg-indigo-600 dark:bg-indigo-700 hover:bg-indigo-700 dark:hover:bg-indigo-800 text-white px-4 py-2 rounded transition-colors duration-300"
        >
          Close
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import he from 'he';
import sanitizeHtml from 'sanitize-html';

export default {
  props: {
    section_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      token: null,
      isLoading: true,
      errorMessage: '',
      classData: null,
      nextMeetingTime: null,
      upcomingAssignments: [],
      gradedAssignments: [],
      showAssignmentModal: false,
      currentAssignment: null,
      showGradedAssignmentModal: false,
      currentGradedAssignment: null,
    };
  },
  methods: {
    extractToken() {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        this.token = storedToken;
      } else {
        // Redirect to login if token is not available
        this.redirectToLogin();
      }
    },
    isTokenExpired(token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiry = payload.exp;
        const now = Math.floor(new Date().getTime() / 1000);
        return now > expiry;
      } catch (error) {
        console.error('Invalid token format:', error);
        return true;
      }
    },
    redirectToLogin() {
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://bcbackend.replit.app/login?originalUrl=${currentUrl}`;
    },
    async fetchClassData() {
      try {
        console.log('Fetching class data for section_id:', this.section_id);
        const response = await axios.get(`https://bcbackend.replit.app/classes/${this.section_id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        this.classData = response.data;
        console.log('Received class data:', this.classData);

        // Process next meeting time
        if (this.classData.next_meeting) {
          this.nextMeetingTime = this.classData.next_meeting.start_time;
          console.log('Next meeting time:', this.nextMeetingTime);
        } else {
          this.nextMeetingTime = null;
        }

        // Process upcoming assignments
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight
        console.log('Today\'s date (midnight):', today);

        this.upcomingAssignments = this.classData.assignments
            .filter((assignment) => {
              const dueDate = this.parseDateTime(assignment.due_date);
              console.log(`Assignment "${assignment.name}" due date:`, dueDate);
              console.log(`Comparing due date >= today: ${dueDate} >= ${today}`);
              return dueDate && dueDate >= today;
            })
            .sort(
                (a, b) => this.parseDateTime(a.due_date) - this.parseDateTime(b.due_date)
            );
        console.log('Upcoming assignments:', this.upcomingAssignments);

        // Process graded assignments
        this.gradedAssignments = this.classData.graded_assignments;
        console.log('Graded assignments:', this.gradedAssignments);
      } catch (error) {
        console.error('Error fetching class data:', error);
        if (error.response && error.response.status === 401) {
          this.redirectToLogin();
        } else {
          this.errorMessage = 'Failed to load class data.';
        }
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(dateString) {
      const date = this.parseDateTime(dateString);
      if (!date) return 'Invalid Date';
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    },
    formatDateTime(dateTimeString) {
      const date = this.parseDateTime(dateTimeString);
      if (!date) return 'Invalid Date';
      return date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    },
    parseDateTime(dateTimeString) {
      if (!dateTimeString) return null;
      // Remove the '+00:00' timezone offset
      const localDateTimeString = dateTimeString.replace('+00:00', '');
      const date = new Date(localDateTimeString);
      console.log('Parsed dateTimeString:', dateTimeString, 'as date:', date);
      return date;
    },
    openAssignmentModal(assignment) {
      const cleanedDescription = this.cleanField(assignment.description);
      this.currentAssignment = { ...assignment, description: cleanedDescription };
      this.showAssignmentModal = true;
    },
    closeAssignmentModal() {
      this.showAssignmentModal = false;
      this.currentAssignment = null;
    },
    openGradedAssignmentModal(assignment) {
      this.currentGradedAssignment = assignment;
      this.showGradedAssignmentModal = true;
    },
    closeGradedAssignmentModal() {
      this.showGradedAssignmentModal = false;
      this.currentGradedAssignment = null;
    },
    cleanField(field) {
      if (!field) return '';
      // Replace <br> and <p> tags with newlines
      let withLineBreaks = field.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?p>/gi, '\n');
      // Decode HTML entities
      withLineBreaks = he.decode(withLineBreaks);
      // Strip any remaining HTML tags
      const cleanText = sanitizeHtml(withLineBreaks, { allowedTags: [], allowedAttributes: {} });
      return cleanText.trim();
    },
  },
  watch: {
    section_id(newId, oldId) {
      if (newId !== oldId) {
        console.log(`section_id changed from ${oldId} to ${newId}. Fetching new class data.`);
        this.isLoading = true;
        this.errorMessage = '';
        this.classData = null;
        this.nextMeetingTime = null;
        this.upcomingAssignments = [];
        this.gradedAssignments = [];
        this.fetchClassData();
      }
    },
  },
  created() {
    this.extractToken();
    if (this.token && !this.isTokenExpired(this.token)) {
      this.fetchClassData();
    } else {
      this.redirectToLogin();
    }
  },
};
</script>

<style scoped>
/* Remove global body styles to prevent conflicts. Consider moving these to a global CSS file or App.vue. */
main {
  @apply min-h-screen flex flex-col;
}

/* Spinner Animation */
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Adjustments */
@media (max-width: 640px) {
  /* Adjust font sizes for mobile */
  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  .px-4 {
    @apply px-2;
  }
}

/* Table Styling */
table th,
table td {
  @apply text-left py-2;
}

table th {
  @apply bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 font-semibold;
}

tr:nth-child(even) {
  @apply bg-gray-50 dark:bg-gray-800;
}

tr:nth-child(odd) {
  @apply bg-white dark:bg-gray-800;
}

tr:hover {
  @apply bg-gray-100 dark:bg-gray-700;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-600 rounded;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400 dark:bg-gray-700;
}
</style>
