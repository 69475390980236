
import { createRouter, createWebHistory } from 'vue-router';
import Assignments from '@/views/Assignments.vue';
import Schedule from '@/views/Schedule.vue';
import Landing from '@/views/Landing.vue';
import Dashboard from '@/views/Dashboard.vue';
import Resources from '@/views/Resources.vue';
import NotFound from '@/views/404.vue';
import Privacy from '@/views/Privacy.vue';
import ClassPage from '@/views/ClassPage.vue';
import Terms from '@/views/Terms.vue';

// Remove Firebase Auth imports and code since we're using JWT tokens stored in localStorage

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/assignments',
    name: 'Assignments',
    component: Assignments,
    meta: { requiresAuth: true },
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule,
    meta: { requiresAuth: true },
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
    meta: { requiresAuth: true },
  },
  {
    path: '/class/:section_id',
    name: 'ClassPage',
    component: ClassPage,
    meta: { requiresAuth: true },
    props: true, // Pass route params as props to the component
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    props: true, // Pass route params as props to the component
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    props: true, // Pass route params as props to the component
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route for undefined paths
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  // Extract token from URL if present
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');

  if (tokenFromUrl) {
    localStorage.setItem('token', tokenFromUrl);
    // Remove token from URL
    urlParams.delete('token');
    const newUrl =
        window.location.pathname +
        (urlParams.toString() ? '?' + urlParams.toString() : '');
    window.history.replaceState({}, document.title, newUrl);
  }

  const token = localStorage.getItem('token');
  const isAuthenticated = !!token;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // If route requires auth and user is not authenticated, redirect to landing
    if (!isAuthenticated) {
      next('/'); // Redirect to Landing.vue
    } else {
      next(); // Proceed to the route
    }
  } else {
    // If the user is already logged in, prevent access to the landing page
    if (to.path === '/' && isAuthenticated) {
      next('/dashboard'); // Redirect to Dashboard or any other logged-in page
    } else {
      next(); // Proceed to the route
    }
  }
});

export default router;
